import React from "react";
import publisher_logo from "../Assets/Images/publisher_demo.png";

const AboutSouthshore = () => {
    return (
        <div className="container py-5">
            <div className="row">
                <div className="col-md-5 ab_mar_x">
                    {/*  about-us-left-section */}


                    <div className="ab_south_pub_name mb-4">
                        {/* publisher-name */}
                        A marketplace for <span>e-books</span> across genres and languages</div>
                    <div className="ab_south_pub_des">Where Genres and languages unite: Your <span>E-Book Haven</span></div>

                </div>
                <div className="col-md-7 ab_mar_x">
                    {/* about-us-right-section */}
                    {/* <p>
                        e-books junction is your passport to an extensive, ever-expanding library of e-books, thoughtfully curated from esteemed publishers both in India and abroad. As an avid reader, a dedicated student, or a professional striving for success, e-books junction.is your trusted companion on your intellectual journey.
                    </p>
                    <p>
                        Why choose e-books? The benefits are undeniable. With e-books junction, you'll experience a world of advantages at your fingertips. First and foremost, accessibility. Our e-books are available 24/7, allowing you to read and study at your convenience. No more heavy backpacks or searching for a particular book in a crowded library.
                    </p>
                    <p>
                        Moreover, e-books are eco-friendly, reducing your carbon footprint, and you can carry an entire library with you wherever you go on a single device. e-books junction enhances your learning experience with search functions, highlights, and annotations, making it easier to grasp key concepts and return to them effortlessly. Our platform's extensive range e-books across genres and languages ensures you have a world of knowledge within reach. Enjoy your reading!
                    </p> */}
                    <div className="r_des_1 mb-5">
                        E-Books Junction" is an online platform or website dedicated to providing a wide variety of 
                        electronic books, commonly known as e-books, to readers. It serves as a virtual hub 
                        where individuals can explore, purchase, and read  ebooks spanning different genres and topics. 
                        EBooks Junction offers a user-friendly interface that allows users to search for, 
                        preview, and download e-books in digital formats compatible with e-readers, tablets, smartphones, 
                        or computers.

                    </div>
                    <div className="r_des_2">
                        The platform features a diverse collection of e-books, including fiction, 
                        non-fiction, self-help, professional, academic, reference books, and more. 
                        This collection across genres and languages is from different publishers 
                        both Indian and international. 
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AboutSouthshore;