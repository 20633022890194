import React, { useEffect, useState, } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import Banner from "../Assets/Images/Banner.png";
import HomeBanner from "../Assets/Images/HomeBanner.png";
import JPBanner from "../Assets/Images/JurisPressBanner.png";
import MPBanner from "../Assets/Images/ManoharPublishersBanner.png";

import { UserProfile } from "../Context/Usercontext";
import { useAuth } from '../Context/Authcontext';
import Config from "../Config/Config.json"



const TopBanner = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const { getPublishersById, publisherId } = UserProfile();
    const { authData } = useAuth();
    
    const [publisherDetails, setPublisherDetails] = useState('')

    useEffect(() => {
        getPubById();
    }, [authData])

    const getPubById = async () => {
        let pubid = 0;
        if (location.state === null || location.state === 'null') {
          pubid = publisherId
        }
        else {
          pubid = location.state.publisher_id
        }
        const result = await getPublishersById(pubid)
        console.log("RESULT from Banner ===>",result);
        setPublisherDetails(result?.data?.output)
    
    }

    // let bannerImage;

    // if (publisherDetails.id === 15) {
    //     // If the publisher is Juris Press then use their banner
    //     bannerImage = <img src={JPBanner} className="ban_back_img" alt="Banner 1" />;
    // } else if (publisherDetails.id === 26) {
    //     // If the publisher is Manohar Publishers then use their banner
    //     bannerImage = <img src={MPBanner} className="ban_back_img" alt="Banner 2" />;
    // } else {
    //     // Else use default banner
    //     bannerImage = <img src={HomeBanner} className="ban_back_img" alt="Default Banner" />;
    // }

    return (
        <div className="top-banner">
            {/* <img src={Banner}/> */}
            <div className="row">
                <div className="col-md-6">
                    {/* ban_back_img */}
                    <div>
                        <img 
                            src={publisherDetails.banner === null || publisherDetails.banner === '' ? HomeBanner : Config.API_URL + Config.PUB_IMAGES + publisherDetails.id + "/" + publisherDetails.banner + '?d=' + new Date()} 
                            className="ban_back_img"
                            loading="lazy"
                        />
                        {/* {bannerImage} */}
                    </div>
                </div>
                <div className="col-md-6 banner_txt">
                    <div className="ban_head mb-2">Your Gateway to Excellence</div>
                    <div className="ban_body mb-2">Explore Academic and Professional E-Books with Ease</div>
                    <div className="ban_foot mb-4">Read the World, One Page at a Time</div>
                    {/* <div className="d-flex mt-5">
                        <button className="buy_btn">Buy Now</button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default TopBanner;